<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-row class="mb-n10">
                            <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
                                <v-col class="mt-n3 mb-3">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtinicio"
                                        label="Data inicial"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-col class="mt-n3 mb-3 px-0">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtfim"
                                        label="Data final"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-btn
                                    :disabled="carregando"
                                    class="ml-2 mt-1"
                                    color="primary"
                                    elevation="0"
                                    fab
                                    x-small
                                    @click="get()"
                                >
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="primary" class="white--text">
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-title class="text-truncate">Controle de Frete FOB</v-card-title>
                <v-card-text v-if="carregando">
                    <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
                </v-card-text>
                <v-card-text v-else>
                    <v-data-table
                        mobile-breakpoint="0"
                        v-if="dados.lista"
                        :dark="false"
                        dense
                        :items-per-page="1000"
                        :headers="[
                            {text: 'Fornecedor', value: 'fornecedor', class:'text-truncate'},
                            {text: 'Transportadora', value: 'transportadora', class:'text-truncate pl-0'},
                            {text: 'Nº Nota', value: 'numnota', class:'text-truncate pl-0'},
                            {text: 'Valor Nota', value: 'valornota', class:'text-truncate pl-0'},
                            {text: 'Valor Frete', value: 'valorfrete', class:'text-truncate pl-0'},
                            {text: '% Frete', value: 'percfrete', class:'text-truncate pl-0',align: 'center', sortable:false},
                            {text: 'Peso', value: 'pesobruto', class:'text-truncate pl-0', align:'center ', sortable:false},
                            {text: 'Data Emissão', value: 'dtemissao', class:'text-truncate pl-0 pr-2'},
                            {text: 'Data Entrega', value: 'dtchegada', class:'text-truncate pl-0 pr-2'},
                            {text: 'Localidade', value: 'localidade', class:'text-truncate', align:'center pl-0', sortable:false},
                        ]"
                        :items="dados.lista.map((u) => {
                            return {
                                numnota: u.numnota,
                                fornecedor: u.fornecedor,
                                transportadora: u.transportadora,
                                dtemissao: u.dtemissao,
                                dtchegada: u.dtchegada,
                                valornota: u.valornota,
                                valorfrete: u.valorfrete,
                                percfrete: u.percfrete,
                                pesoliquido: u.pesoliquido,
                                pesobruto: u.pesobruto,
                                localidade: u.localidade,
                                uf: u.uf,
                            };
                        })"
                        hide-default-footer
                        class="elevation-0"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td class="text-truncate" style="max-width: 250px">
                                    {{ item.fornecedor }}
                                </td>
                                <td class="text-truncate pl-0" style="max-width: 240px">
                                    {{ item.transportadora }}
                                </td>
                                <td class="pl-0">{{ item.numnota }}</td>
                                <td class="pl-0">{{ item.valornota | formataDinheiro}}</td>
                                <td class="pl-0">{{ item.valorfrete | formataDinheiro}}</td>
                                <td class="text-truncate text-center pl-0">
                                    {{ item.percfrete.toFixed(2) }} %
                                </td>
                                <td class="text-truncate text-center pl-0">
                                    <span v-if="item.pesobruto">{{ item.pesobruto.toFixed(3) }}</span>
                                </td>
                                <td class="text-truncate pl-0">
                                    {{ item.dtemissao | formataData }}
                                </td>
                                <td class="text-truncate pl-0">
                                    {{ item.dtchegada | formataData }}
                                </td>
                                <td class="text-center text-truncate pl-0">
                                    <span v-if="item.localidade">{{ item.localidade }} - {{ item.uf }}</span>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:foot>
                            <tr class="font-weight-bold blue-grey lighten-4">
                                <td class="pl-4" height="30px">Total</td>
                                <td>{{dados.total}} notas </td>
                                <td></td>
                                <td class="pr-4 pr-4">{{dados.lista.map(v => v.valornota).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="pr-4">{{dados.lista.map(v => v.valorfrete).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="pr-4 text-center text-truncate">{{(dados.lista.map(v => v.valorfrete).reduce((a,v) => a + v) / dados.lista.map(v => v.valornota).reduce((a,v) => a + v)*100).toFixed(2)}} %</td>
                                <td class="pl-1">{{dados.lista.map(v => v.pesobruto).reduce((a,v) => a + v).toFixed(3) }}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-alert class="mb-0" v-else>Nenhum registro encontrado</v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";

export default {
    name: "DashboardFinanceiro",
    mixins: [mixinFilial],
    components: {
        InputDatePicker
    },
    data: () => ({
        sheet: false,
        carregando: false,
        dados: {},
        busca: {
            dtinicio: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-01`,
            dtfim:
                `${new Date(new Date().getFullYear(),new Date().getMonth() + 1,0).getFullYear()}-${
                    (new Date( new Date().getFullYear(),new Date().getMonth() + 1,0).getMonth() + 1 < 10 ? "0": "") +(new Date().getMonth() + 1)}-${
                    (new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getDate() < 10 ? "0": "") + (new Date().getDate())}`,
            freteporconta: null,
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        get() {
            this.dados = {};
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}dashboard/cd/nota-fornecedor`, {
                    dtinicio: this.busca.dtinicio,
                    dtfim: this.busca.dtfim,
                    freteporconta: this.busca.freteporconta,
                })
                .then((res) => {
                    this.dados = res.data
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
        async init() {
            await this.get();
        },
    },
    watch: {
        "busca.dtfim": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
    },
    created() {
        this.init();
    },
};
</script>